import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { PageProps, navigate } from 'gatsby'

// Models | Types
import { TownModel } from '../../../models'

// Config
import { town } from '../../../config/localization'

// Templates
import PageTemplate from '../PageTemplate'
import WorldTemplate from '../WorldTemplate'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// GraphQL
import { getGraphTowns } from '../../../graphQL'

// Utils
import { createPath, getFont } from '../../../utils/utils'

// Hooks
import useWindowSize from '../../../hooks/windowSize'

// Style
import {
  townLeft,
  townRight,
  townEmblem,
  townRelated,
  townContainer,
  townImage,
  townImageWrapper,
  townImageOverlay,
} from './styles.module.scss'

const TownTemplate: React.FC<PageProps<any, TownModel>> = ({
  pageContext: { name, description, relatedTowns },
}) => {
  const { language } = useContext(LocalizationContext)

  const { width } = useWindowSize()

  const allTowns = getGraphTowns().filter((town) => town.name !== 'Unknown')
  const related = relatedTowns.map((tw) =>
    allTowns.find((related) => Number(related.id) === tw)
  )

  const [localization, setLocalization] = useState(town.Default)

  useEffect(() => {
    const newLocalization = town[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
  }, [language])

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {language === 'ESP' ? 'Mapa' : 'Map'}
          {`: ${name}`}
        </title>
        <meta
          name="description"
          content={description ? description[language] : ''}
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? `Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, homepage, mapa, ${name}`
              : `Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, homepage, map, ${name}`
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <WorldTemplate
        location="map"
        title={name}
        icon={
          width < 1024
            ? `https://dilancovak.com/.img/emblems/${createPath(name)}.png`
            : undefined
        }
      >
        <div className={`${townContainer} ${getFont('font-lato')}`}>
          <div className={townImageWrapper}>
            <img
              className={`${townImage}  only-mobile-tb`}
              src={`https://dilancovak.com/.img/map/${createPath(name)}1.png`}
            />
            <div className={townImageOverlay} />
          </div>
          <div className={townLeft}>
            <img
              className={`${townEmblem} only-desktop`}
              src={`https://dilancovak.com/.img/emblems/${createPath(
                name
              )}.png`}
            />
            {description[language]}
          </div>
          <div className={townRight}>
            <div className={townImageWrapper}>
              <img
                className={`${townImage}  only-desktop`}
                src={`https://dilancovak.com/.img/map/${createPath(name)}1.png`}
              />
              <div className={townImageOverlay} />
            </div>
            <h3>{town[language].related}</h3>
            <div>
              {related.map((tw) => (
                <img
                  key={`releated_town_${tw?.name}`}
                  className={townRelated}
                  src={`https://dilancovak.com/.img/emblems/${createPath(
                    (tw as TownModel).name
                  )}.png`}
                  onClick={() => {
                    navigate(`/world/map/${createPath((tw as TownModel).name)}`)
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </WorldTemplate>
    </PageTemplate>
  )
}

export default TownTemplate
